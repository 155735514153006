<template>
  <div class="my-5">
    <div class="flex justify-between items-center mb-6">
      <div>
        <h1 class="text-4xl">Transactions</h1>
      </div>

      <div class="flex">
        <div>
          <input
            class="p-2 border w-80 rounded"
            v-model="filter"
            @keyup="search()"
            type="search"
            placeholder="Search by customer or meter number"
          />
        </div>
      </div>
    </div>

    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-0 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div
            class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
          >
            <!-- <div
              v-if="onFilter"
              class="flex grid-flow-col gap-4 bg-gray-50 p-3"
            >
              <div class="flex flex-col w-80">
                <label class="text-gray-600" for="branch"
                  >Filter by status
                </label>
                <select
                  class="p-2 border rounded"
                  name="branch"
                  @change="loadData(1, filter)"
                  v-model="filterStatus"
                >
                  <option value="" disabled selected>Filter by status</option>
                  <option value="INITIATED">INITIATED</option>
                  <option value="PROCESSING">PROCESSING</option>
                  <option value="PROCESSED">PROCESSED</option>

                  <option value="">All</option>
                </select>
              </div>
              <div class="flex flex-col w-80">
                <label class="text-gray-600" for="branch"
                  >Filter by branch
                </label>
                <select
                  class="p-2 border rounded"
                  name="branch"
                  @change="loadData(1, filter)"
                  v-model="filterBranch"
                >
                  <option value="" disabled selected>Filter by branch</option>
                  <option value="">All</option>

                  <option
                    v-for="(branch, i) in branches"
                    :key="i"
                    :value="branch.id"
                  >
                    {{ branch.name }}
                  </option>
                </select>
              </div>

              <div class="flex flex-col w-80">
                <label class="text-gray-600" for="branch"
                  >Filter by staff
                </label>
                <select
                  class="p-2 border rounded"
                  name="branch"
                  @change="loadData(1, filter)"
                  v-model="filterStaff"
                >
                  <option value="" disabled selected>Filter by staff</option>
                  <option
                    v-for="(staff, i) in staffs"
                    :key="i"
                    :value="staff.id"
                  >
                    {{ staff.name }}
                  </option>
                </select>
              </div>

              <div class="flex flex-col w-80">
                <label class="text-gray-600" for="branch"
                  >Filter by date
                </label>
                <input
                  class="p-2 border rounded text-xs"
                  type="date"
                  name="date"
                  @change="loadData(1, filter)"
                  v-model="filterDate"
                />
              </div>
            </div> -->
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Customer
                  </th>

                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Agent
                  </th>

                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Amount/Units
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Date/Time
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Status
                  </th>

                  <th scope="col" class="relative px-6 py-3">
                    <span class="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>

              <tbody v-if="isLoading">
                <tr class="">
                  <td colspan="7">
                    <spinner :show="isLoading" :content="`Loading `" />
                  </td>
                </tr>
              </tbody>
              <tbody v-else class="bg-white divide-y divide-gray-200">
                <tr v-for="(transaction, i) in transactions.data" :key="i">
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="ml-4">
                        <div class="text-sm font-medium text-gray-900">
                          {{ transaction.customer }}
                        </div>
                        <div class="text-sm text-gray-500">
                          {{ transaction.meter_serial }}
                        </div>
                      </div>
                    </div>
                  </td>

                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="text-sm text-gray-900">
                      {{ transaction.staff }}
                    </div>
                  </td>

                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="text-sm font-bold text-gray-900">
                      GMD {{ formatPrice(transaction.total) }} /
                      {{ transaction.units }}.kWh
                    </div>
                  </td>

                  <td class="px-6 py-4 w-20 whitespace-nowrap">
                    <div class="text-sm font-bold text-gray-900">
                      {{
                        transaction.created | moment("Do MMM, YYYY, h:mm:ss a")
                      }}
                    </div>
                  </td>

                  <td
                    class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium"
                  >
                    <button
                      v-if="transaction.status_val != 'CANCELED'"
                      @click="viewTrans(transaction)"
                      class="text-indigo-600 hover:text-indigo-900"
                    >
                      <span v-if="transaction.status">View</span>
                      <span v-else>Retry</span>
                    </button>
                  </td>
                </tr>

                <!-- More people... -->
              </tbody>
            </table>

            <div class="border-t" v-if="transactions">
              <pagination :data="transactions" :midSize="2" @clicked="loadData">
              </pagination>
            </div>
          </div>

          <modal
            width="50%"
            height="100%"
            :adaptive="true"
            :scrollable="true"
            :resizable="true"
            name="example"
          >
            <div class="flex justify-center items-center my-3">
              <div class="flex">
                <div>
                  <button
                    @click="print()"
                    class="uppercase text-gray-700 font-bold py-3 px-4 border border-gray-400 rounded-lg hover:bg-gray-100 text-white"
                  >
                    <i class="bx bx-printer"></i>

                    Print
                  </button>
                </div>
              </div>
            </div>

            <transaction
              :data="selectedTransaction"
              :loading="isLoadingTrans"
              :errMessage="errMessage"
            />
          </modal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import Pagination from "../../components/Pagination";
import FormatPrice from "@/mixins/FormatPrice";
import Transaction from "./Transaction";
import { mapGetters } from "vuex";

import JSPM from "../../../public/JSPrintManager";

export default {
  middleware: "auth",
  layout: "default",
  components: {
    Pagination,
    Transaction,
    // BranchSales,
    // HelloWorld
  },

  mixins: [FormatPrice],
  computed: mapGetters({
    user: "auth/user",
  }),

  data: () => ({
    transactions: [],
    searching: false,
    filter: "",

    print2default: true,
    selectedPrint: null,

    selectedTransaction: null,

    onFilter: false,
    isLoading: false,

    filterBranch: "",
    filterStaff: "",
    filterDate: "",
    filterStatus: "",
    errMessage: "",
    isLoadingTrans: false,
    lastPage: 1,
    branches: [],
    staffs: [],
  }),

  created() {
    var _this = this;
    _this.loadData(1);
  },

  mounted: function () {
    this.onInit();

    // console.log(this.data);
  },

  methods: {
    //
    async clickCallback(pageNum) {
      this.page = pageNum;
      this.loadData(pageNum, this.filter);

      // await this.$store.dispatch("customer/getItems", data);
    },
    viewTrans(trans) {
      this.isLoadingTrans = true;
      this.$modal.show("example");
      this.loadTransaction(trans.id);
    },

    async loadTransaction(id) {
      this.errMessage = "";
      try {
        const { data } = await axios.get(`/agent/transaction/${id}`);
        if (data.status === "T303") {
          this.isLoadingTrans = false;
          this.errMessage = data.message;
          return false;
        }
        this.selectedTransaction = data.data;
        this.isLoadingTrans = false;

        return true;
      } catch (e) {
        console.log("Error");
        this.isLoadingTrans = false;

        return false;
      }
    },

    async loadData(pageNo, filter) {
      // Submit the form

      if (filter) {
        // filter = filter;
        this.searching = true;
        // this.isLoading = false;
      } else {
        filter = "";
        this.searching = false;
        // this.isLoading = false;
      }

      this.isLoading = true;
      try {
        const { data } = await axios.get(
          `/agent/transactions?page=${pageNo}&filter=${filter}&date=${this.filterDate}`
        );

        console.log("data", data.meta);

        this.transactions = data;
        this.searching = false;
        this.isLoading = false;
        this.lastPage = data.meta.last_page;

        return true;
      } catch (e) {
        console.log("Error");
        this.searching = false;
        this.isLoading = false;

        return false;
      }
    },

    print: function () {
      const receipt = this.selectedTransaction;

      console.log("RECEIPT", receipt);

      if (this.selected_printer === "" && !this.print2default) {
        alert("You must select a printer");
        return;
      }

      var cpj = new JSPM.ClientPrintJob();

      if (this.print2default) {
        cpj.clientPrinter = new JSPM.DefaultPrinter();
      } else {
        cpj.clientPrinter = new JSPM.InstalledPrinter(this.selected_printer);
      }

      var dateCreated = moment(receipt.created).format(
        "MMM Do YYYY, h:mm:ss a"
      );

      var esc = "\x1B"; //ESC byte in hex notation
      var newLine = "\x0A"; //LF byte in hex notation
      var fullCut = esc + "m";

      var cmds = esc + "@"; //Initializes the printer (ESC @)
      cmds += esc + "!" + "\x38"; //Emphasized + Double-height + Double-width mode selected (ESC ! (8 + 16 + 32)) 56 dec => 38 hex
      cmds += "CHAPMAN XPRESS"; //text to print
      cmds += newLine;
      cmds += esc + "!" + "\x00"; //Character font A selected (ESC ! 0)

      cmds += newLine + newLine;

      cmds += `Receipt No .: ${receipt.receipt}`;
      cmds += newLine + newLine;
      cmds += `Meter No .: ${receipt.meter_serial}`;
      cmds += newLine;

      cmds += esc + "!" + "\x22";
      cmds += `${receipt.customer}`;
      cmds += esc + "!" + "\x00";

      cmds += newLine;
      cmds += `${receipt.meter_serial}`;
      cmds += newLine + newLine;
      cmds += "------------------ Token Numbers ---------------";
      cmds += newLine;

      receipt.list_tokens.forEach((token) => {
        cmds += esc + "1" + "\x01  ";
        cmds += newLine;
        cmds += "********************************************";
        cmds += newLine + newLine;
        cmds += esc + "!" + "\x30"; //Emphasized + Double-height mode selected (ESC ! (16 + 8)) 24 dec => 18 hex
        cmds += `${this.formatToken(token)}`;
        cmds += newLine + newLine;
        cmds += esc + "!" + "\x00";
        cmds += "********************************************";
        cmds += newLine + newLine;
      });

      cmds += `Amount Paid .:       GMD ${Number(receipt.total)}`;
      cmds += newLine;
      cmds += `Fee .:               GMD ${receipt.fee}`;
      cmds += newLine;
      cmds += `Cashpower Amount .:  GMD ${receipt.amount}`;
      cmds += newLine;
      cmds += `Total Units .:       ${receipt.units}kWh`;
      cmds += newLine;

      cmds += `Payment Date .:      ${dateCreated}`;

      cmds += newLine + newLine;
      cmds += esc + "!" + "\x18"; //Emphasized + Double-height mode selected (ESC ! (16 + 8)) 24 dec => 18 hex
      cmds += `Operator: ${receipt.staff}`;
      cmds += newLine;
      cmds += esc + "!" + "\x00"; //Character font A selected (ESC ! 0)

      cmds += "Use Energy Wisely!";
      cmds += newLine;

      cmds += newLine;
      cmds += `Phone: +220 438 3223 - 307 4455 -`;
      cmds += newLine + newLine;
      cmds += newLine + newLine;

      cmds += fullCut;

      cpj.printerCommands = cmds;
      //Send print job to printer!
      cpj.sendToClient();

      // this.receipt = null;
    },

    search() {
      var vm = this;
      this.searching = true;
      vm.loadData(1, vm.filter);
    },

    formatDate(date) {
      var day = new Date(date);

      return moment().utc(day).format("Do MMM, YYYY, h:mm:ss a");
      // console.log(day.format('dddd MMMM Do YYYY, h:mm:ss a'));
    },

    onInit: function () {
      console.log("IN INIT", JSPM);
      var _this = this;
      JSPM.JSPrintManager.auto_reconnect = true;
      JSPM.JSPrintManager.start();
      JSPM.JSPrintManager.WS.onStatusChanged = function () {
        _this.getPrinters().then((p) => {
          _this.printers = p;
          _this.$nextTick();
        });
      };
    },

    getPrinters: function () {
      return new Promise((ok, err) => {
        let printers = [];
        if (JSPM.JSPrintManager.websocket_status == JSPM.WSStatus.Open) {
          JSPM.JSPrintManager.getPrinters()
            .then(function (myPrinters) {
              printers = myPrinters;
              console.log(printers);
              ok(printers);
            })
            .catch((e) => err(e));
        } else {
          console.warn("JSPM WS not open");
          ok(printers);
        }
      });
    },

    async loadStaffs() {
      // Submit the form

      try {
        const { data } = await axios.get(`/staff/all`);

        this.staffs = data.data;
        // this.isLoading = false;

        return true;
      } catch (e) {
        console.log("Error");
        this.isLoading = false;
        return false;
      }
    },

    async loadBranches() {
      // Submit the form

      try {
        const { data } = await axios.get(`/get/branches`);

        this.branches = data.data;
        // this.isLoading = false;

        return true;
      } catch (e) {
        console.log("Error");
        this.isLoading = false;
        return false;
      }
    },

    formatToken(token) {
      return token.match(/\d{4}(?=\d{2,3})|\d+/g).join("-");
    },
  },
};
</script>
<style lang="css">
.pagination {
  widows: 100%;
  padding: 1rem;
  margin-top: 1rem;
}
.page-item {
  /* background: #000; */
  margin-left: 10px;
}
.next {
  position: absolute;
  right: 10%;
  background: rgb(7, 7, 184);
  color: #fff;
  border-end-end-radius: 0.7em;
  padding: 0.1rem;
  padding-inline: 2rem;
}
.prev {
  color: #000;
  padding-inline: 1.5rem;
  /* color: #fff; */
  background-color: rgb(183, 187, 196);
  border-start-start-radius: 0.7em;
  padding: 0.1rem;
  padding-inline: 1.5rem;
}
.active {
  color: #000;
  font-weight: bolder;
}
</style>
